<template>
  <div>
    <ul class="list">
      <div class="listIcon">
        <van-image
          width="20px"
          height="20px"
          :src="require('@/assets/images/list.png')"
        />
        <h2>消息列表</h2>
      </div>

      <li v-for="(item, i) in newsList" :key="i">
        <h2 class="caseTitle">{{ item.title }}</h2>
        
        <div class="info">
          
          <div>
            <span style="color: #666">状态：</span>
            <span class="" v-if="item.is_Read">已读</span>
            <span class="" v-if="!item.is_Read">未读</span>
          </div>
          
          <div>
            <span style="color: #666">发布时间：</span>
            <span>{{ item.publish_date }}</span>
          </div>
          
          <div class="newsContent">
            <div v-html="item.contents"></div>
          </div>
        </div>
        
        <div class="fnBtn">
          <van-button
            type="primary"
            size="mini"
            color="rgb(217,58,69)"
            round
            @click.stop="toInfo(i)"
            >查看详情</van-button
          >
        </div>
      </li>
      
      <div class="nothing">
        <van-empty
          v-if="!list.length"
          :image="require('@/assets/images/kong.png')"
          image-size="80"
          description="暂时没有消息"
        />
      </div>
    </ul>
    
    <van-overlay :show="showPreText" @click="showPreText = false">
      <div class="descOverlay">
        <div class="block" v-html="preText"></div>
      </div>
    </van-overlay>
  </div>
</template>

<script setup>
import { Toast } from "vant";
import { defineProps, reactive, ref } from "vue";
import { useRouter } from "vue-router";

import { apiStudentToRead } from "@/service/notice";

const router = useRouter();
let props = defineProps(["list"]);

let newsList = ref(props.list);
let showPreText = ref(false);
let preText = ref("");

const toInfo = async (i) => {
  let item = newsList.value[i];
  showPreText.value = true;
  preText.value = item.contents;
  
  newsList.value[i].is_Read = 1;
  
  let formData = new FormData();
  formData.append('newsId', item.id);
  await apiStudentToRead(formData)
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";
.listIcon {
  display: flex;
  margin-bottom: 10px;
  h2 {
    margin: 0;
    font-size: 10px;
    margin-left: 10px;
  }
}
.list {
  padding: 10px;
  li {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 12px;
    border-radius: 8px;
    .bs();
    h2 {
      margin: 0;
      font-size: 12px;
    }

    .subjectAndTree {
      color: #666;
    }

    .infoIndex {
      color: #777;
    }
    .infoIndex {
      background-color: #fff;
    }
    .subject {
      padding: 0 7px;
      border-radius: 10px;
      background-color: rgb(191, 109, 67);
      color: #fff;
    }

    .fnBtn {
      display: flex;
      justify-content: end;
      :deep(.van-button) {
        margin-left: 10px;
        padding: 0 15px;
      }
    }
  }
}

:deep(.van-divider) {
  margin: 0;
  margin-bottom: 10px;
}

.info {
  /* display: flex; */
  margin: 10px 0;

  .newsContent {
    margin-top: 10px;
    line-height: 14px;
    height: 50px;
    overflow: hidden;
  }
  
  .redText{
    color: #ff0000;
  }
  .greenText{
    color: #00ff00;
  }
}

.descOverlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 100%;
}
.descOverlay .block {
  background-color: #fff;
  line-height: 3vh;
  width: 80vw;
  max-height: 80vh;
  border-radius: 8px;
  padding: 8px;
  overflow-y: auto;
  white-space: pre-wrap;
}

/* 横屏 */
@media screen and (orientation: landscape) {
  .descOverlay .block {
    margin-left: 50px;
  }
}
</style>
