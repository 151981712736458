<template>
  <div class="about">
    <sHeader :title="'消息通知'"></sHeader>
    <nBar :userType="'student'" :activeItemName="'news'"></nBar>
    <support></support>

    <div class="pageContent">
      <div class="modular-content" style="margin: 0 auto;">
        <div class="modular-item">
          <van-list
            v-model:loading="newsLoading"
            :finished="newsLoadingFinished"
            :finished-text="newsList.length ? '没有更多了' : ''"
            @load="getNewsList"
          >
            <nw-list
              :showLine="true"
              lineText="列表"
              lineTextPosition="left"
              listType="common"
              :list="newsList"
            />
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, defineProps } from "vue";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import nwList from "@/components/student/NewsList";

import { apiGetStudentLists } from "@/service/notice";

let userInfo = JSON.parse(localStorage.getItem("userInfo"));

defineProps([]);

onBeforeMount(async () => {
  
});

let newsList = ref([]);
let newsLoading = ref(false);
let newsLoadingFinished = ref(false);
let newsPage = ref(0);
let newsLimit = ref(10);

const getNewsList = async () => {
  newsLoading.value = true;
  newsLoadingFinished.value = false;
  newsPage.value++;
  
  const params = {
    page: newsPage.value,
    limit: newsLimit.value,
  };
  
  const apiRes = await apiGetStudentLists(params);
  if(apiRes){
    for (let i = 0; i < apiRes.data.length; i++) {
      let item = apiRes.data[i];
      newsList.value.push(item);
    }
    newsLoading.value = false;
    if (apiRes.data.length < newsLimit.value) {
      newsLoadingFinished.value = true;
    }
  }else{
    newsLoading.value = false;
    newsLoadingFinished.value = true;
  }
}

</script>

<style lang="less" scoped>
@import "@/common/style/pageContent.less";

.pageContent {
  padding: 0 10px;
  padding-bottom: 40px;
}

@media screen and (orientation: portrait) {
  .pageContent {
    padding: 0 10px;
    padding-bottom: 100px;
  }
}
</style>
