<template>
  <div id="studentSis"></div>
</template>

<script setup>
import { onMounted } from "vue";
import { apiGetEcheatsData } from "@/service/student.js";

import * as echarts from "echarts/core";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  GridComponent,
  BarChart,
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
]);

onMounted(async () => {
  var chartDom = document.getElementById("studentSis");
  var myChart = echarts.init(chartDom);

  let res = await apiGetEcheatsData();

  var option;

  option = {
    color: "#18a4e0",
    title: {
      text: "本学年各科学习用时分布",
    },
    tooltip: {
      trigger: "axis",
      valueFormatter: (value) => value + "分钟",
    },
    xAxis: {
      type: "category",
      data: res.data.subjectName,
    },
    yAxis: {
      type: "value",
      name: "单位（分钟）",
      nameTextStyle: {
        color: "#aaa",
        nameLocation: "start",
      },
    },
    series: [
      {
        data: res.data.timeTotal,
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },
    ],
  };

  option && myChart.setOption(option);
});
</script>

<style lang="less" scoped>
#studentSis {
  height: 300px;
  width: 100%;
  padding-top: 5px;
  background-color: #fff;
  border-radius: 8px;
}
</style>
