<template>
  <div class="about">
    <sHeader :title="'学情分析'"></sHeader>
    <nBar :userType="'student'" :activeItemName="'analysis'"></nBar>
    <support></support>

    <div class="pageContent">
      <h2 class="title">学习投入</h2>
      <ul class="studyTotal">
        <li v-for="item in studyTotal">
          <div>
            <span class="num">{{
              item.type == "timestamp" ? formatSeconds(item.num) : item.num
            }}</span
            >{{ item.unit }}
          </div>
          <div class="name">{{ item.text }}</div>
        </li>
      </ul>

      <div class="barBox">
        <StudentSisBar></StudentSisBar>
      </div>

      <div class="subjectTab">
        <van-tabs v-model:active="subjectActive" @change="changeTab">
          <van-tab
            v-for="item in subjectTab"
            :title="item.subjectName"
            animated
          >
            <ul class="studyTotal">
              <li v-for="item in studyTotalFofSubject">
                <div>
                  <span class="num">{{
                    item.type == "timestamp"
                      ? formatSeconds(item.num)
                      : item.num
                  }}</span
                  >{{ item.unit }}
                </div>
                <div class="name">{{ item.text }}</div>
              </li>
            </ul>

            <h2>学习分析</h2>

            <table border="0" class="subjectTab">
              <thead>
                <tr>
                  <th>学案</th>
                  <th>目录</th>
                  <th>学习资源总数</th>
                  <th>做题总数</th>
                  <th>错题总数</th>
                  <th>错误率</th>
                  <th>学习总时长</th>
                </tr>
              </thead>
              <tr v-if="!tableData.length">
                <td colspan="7">
                  <van-empty
                    description="当前科目暂无学案"
                    :image="require('@/assets/images/kong.png')"
                  />
                </td>
              </tr>
              <tr v-for="item in tableData">
                <td>{{ item.title }}</td>
                <td>{{ item.tree_str }}</td>
                <td>{{ item.lst_total }}</td>
                <td>{{ item.sa_total }}</td>
                <td>{{ item.wrong_total }}</td>
                <td>{{ item.wrong_percent }}%</td>
                <td>{{ formatSeconds(item.learning_time_total) }}</td>
              </tr>
            </table>
          </van-tab>
          <div class="pagination">
            <div>
              <van-pagination
                v-model="currentPage"
                :total-items="pageData.total"
                :items-per-page="limit"
                prev-text="<"
                next-text=">"
                @change="changePage"
              />
            </div>
          </div>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, defineProps } from "vue";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import StudentSisBar from "../../chart/StudentSisBar.vue";
import {
  apiGetInputTime,
  apiGetSisSubject,
  apiGetInputForSubject,
  apiGetTableData,
} from "@/service/student.js";
import { formatSeconds } from "@/common/js/utils.js";

let userInfo = JSON.parse(localStorage.getItem("userInfo"));

defineProps([]);

let subjectActive = ref(0);
let studyTotal = ref([]);
let subjectTab = ref([]);
let studyTotalFofSubject = ref([]);
let tableData = ref([]);
let currentPage = ref(1);
let pageData = ref({});
let subjectId = ref(0);

onBeforeMount(async () => {
  await getStudyTotal();
  await getSubject();
  await getTimerForSubject();
  await getSubjectForTableData();
});

// 获取学习投入数据
const getStudyTotal = async () => {
  let res = await apiGetInputTime();
  if (res) {
    studyTotal.value = res.data;
  }
};

// 获取科目列表
const getSubject = async () => {
  let res = await apiGetSisSubject({ gradeId: userInfo.gradeID });
  if (res) {
    subjectTab.value = res.data;
  }
};

// 获取对应科目的学习投入
const getTimerForSubject = async (subjectId) => {
  let res = await apiGetInputForSubject({
    subjectId: subjectId ? subjectId : subjectTab.value[0].id,
  });
  if (res) {
    studyTotalFofSubject.value = res.data;
  }
};

let page = ref(1);
let limit = ref(10);

// 获取表格数据
const getSubjectForTableData = async (subjectId) => {
  let res = await apiGetTableData({
    subjectId: subjectId ? subjectId : subjectTab.value[0].id,
    page: page.value,
    limit: limit.value,
  });
  if (res) {
    tableData.value = res.data.lists;
    pageData.value = res.data.pageData;
  }
};

// 切换tab
const changeTab = async (e) => {
  subjectId.value = subjectTab.value[e].id;
  await getTimerForSubject(subjectId.value);
  await getSubjectForTableData(subjectId.value);
};

// 切换页码
const changePage = async (e) => {
  page.value = e;
  await getSubjectForTableData(subjectId.value);
};
</script>

<style lang="less" scoped>
@import "@/common/style/pageContent.less";

.pageContent {
  padding: 0 10px;
  padding-bottom: 40px;

  .title {
    position: relative;
    padding-left: 15px;
    &::before {
      position: absolute;
      content: "";
      height: 20px;
      width: 8px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #18a4e0;
      border-radius: 10px;
    }
  }

  .studyTotal {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    li {
      background-color: #fff;
      width: 20%;
      padding: 10px;
      border-radius: 8px;
      .num {
        font-weight: 500;
        font-size: 12px;
      }
      .name {
        margin-top: 5px;
      }
    }
  }

  .barBox {
    margin-top: 10px;
  }

  .subjectTab {
    margin-top: 10px;
    width: 100%;
    border-collapse: collapse;
    tr {
      border-bottom: 0.5px solid #d7d7dd;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #ffff;
    }
    thead tr:nth-child(1) {
      background-color: #f1f1f0;
      border-bottom: none;
    }

    tr:nth-last-child(1) {
      border-bottom: none;
    }
    // th {
    //   background-color: #fff;
    // }
  }
}

.pagination {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;

  :deep(.van-pagination) {
    font-size: 10px;
    // height: 0px;
  }
}

@media screen and (orientation: portrait) {
  .pageContent {
    padding: 0 10px;
    padding-bottom: 100px;
  }
}
</style>
